  /* Establecer fondo blanco para el calendario */
.rbc-calendar {
  background-color: #fff;
}

span{
  font-weight: bold !important;
}
.rbc-header{
  background-color:#fe9635 !important;
 
}


.rbc-time-slot{
  background-color: rgb(247, 242, 242)mportant;
}
/* Establecer fondo blanco para las celdas del calendario */
.rbc-month-view .rbc-row-content {
  background-color: #fff;
}

/* Establecer texto negro para los eventos */
.rbc-event {
  background: #2b245a !important;
}

.rbc-agenda-time-cell{
  background:rgb(220, 220, 217)
}


.rbc-toolbar-label{
    text-transform: uppercase;
      font-weight: bold;
      font-size: 24px;
}

.rbc-label{
    font-weight: bold;
}

