.underline {
    border-bottom: 1px solid #000;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.5;
    margin-bottom: 0;
    margin-bottom: 10px;
}

.th-text-right table th {
    text-align: right;
}

table th {
    text-align: right;
}

.contract-details {
    text-align: justify;
}

.note-payable-input {
    height: calc(1.5em + 0.75rem - 10px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin: 0.2rem;
    font-weight: bold;
    text-align: center;
}

.note-payable-input:focus {
    /* Personaliza el aspecto del input cuando está enfocado */
    outline: none; /* Para quitar el sobreado azul predeterminado del navegador */
    border-color: #007bff; /* Cambia el color del borde al azul cuando está enfocado */
    box-shadow: 0 0 5px #007bff; /* Añade una sombra alrededor del input cuando está enfocado */
  }

.signatureCanvas {
    border: 1px solid black;
    width: 100%;
    height: 200px;
}

.contract-backgroud {
    background-image: url('../../src/images/nbp-management/nbp-background-degree.svg');
    background-size: cover;
    background-position: center;
}

.input-contract-form {
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

.input-contract-form:focus {
    /* Personaliza el aspecto del input cuando está enfocado */
    outline: none; /* Para quitar el sobreado azul predeterminado del navegador */
    border-color: #007bff; /* Cambia el color del borde al azul cuando está enfocado */
    box-shadow: 0 0 5px #007bff; /* Añade una sombra alrededor del input cuando está enfocado */
  }