/* Estilos generales */
.custom-checkbox {
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  
  .custom-checkbox .custom-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    background-color: red;
    border-radius: 6px;
  }
  
  .custom-checkbox .custom-input:checked ~ .checkmark {
    background-color: limegreen;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .custom-checkbox .custom-input:checked ~ .checkmark:after {
    display: block;
  }
  
  .custom-checkbox .checkmark:after {
    left: 11px;
    top: 7px;
    width: 9px;
    height: 18px;
    border: solid white;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
  }
  