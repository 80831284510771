.pill {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: bold;
}

.green {
    background-color: rgb(57, 209, 19);
    color: white;
}

.red {
    background-color: rgb(221, 23, 23);
    color: white;
}