body,
html {
    height: 100%;
    margin: 0;
}

.bg-orange {
    background-color: orange;
}

.bg-lime {
    background-color: rgb(73, 222, 73);
}



.bg-indigo {
  background-color: indigo;
}

.bg-light-orange {
  background-color:rgb(255, 100, 51);
}

.bg-light-purple {
  background-color:rgb(175, 51, 255);
}

.bg-light-purple {
  background-color:rgb(175, 51, 255);
}

.bg-magenta{
  background-color:rgb(204,0,102);
}

.bg-nbp-blue{
  background-color: #2b245a ;
}

.bg-nbp-orange{
  background-color:#fe9635;
}

.card-form small {
  color: white !important;;
}

.custom-pill {
    display: inline-block;
    width: 100%;
    color: white;
    padding: 0.55em;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.5s;
    text-transform: uppercase;
    font-size: 0.80em;
    font-weight: bold;
    text-align: center;
    white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  }
  
  .custom-pill::before {
    top: -2px;
    left: -2px;
    border: 2px solid rgba(0, 0, 0, 0.1);
  }
  
  .custom-pill::after {
    bottom: -2px;
    right: -2px;
    border: 2px solid rgba(255, 255, 255, 0.5);
  }
  
  .custom-pill:hover {
    color: #3b3e41;
  }
  
  .custom-pill:hover::before,
  .custom-pill:hover::after {
    opacity: 1;
  }
  