.home-principal-container {

    height: 93vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-principal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 2rem;
  }
  
  .container {
    max-width: 600px;
    width: 100%;
  }
  
  .logo {
    max-width: 100%;
    height: auto;
    margin-top: 1rem;
  }