.gradient-custom-2 {

  background: rgb(33, 7, 118);
}

@media (min-width: 768px) {
  .gradient-form {
    height: 100vh !important;
  }
}

@media (min-width: 769px) {
  .gradient-custom-2 {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem;
  }
}

.nbp-background-logo {
  background-image: url('../../src/images/nbp-management/nbp-background-degree.svg');
  background-size: cover;
  background-position: center;
}

.auth-title {
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: 30px;

}

.auth-paragraph {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #ffffff;
  margin-bottom: 20px;

  /* Degradado de blanco (#ffffff) a amarillo (#ffff00) */
  background-image: linear-gradient(90deg, #ffffff, #ffff00);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}


.auth-slogan {
  font-family: 'Roboto', sans-serif;
  font-size: 40px;
  line-height: 1.6;
  color: #fff;
  margin-bottom: 20px;

  background-image: linear-gradient(45deg, #e5de17, #e17e17);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}